import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { noop, uniqueId } from "lodash";
import { Portal } from "@circle-react-uikit/Portal";
import { ToastContainer } from "./ToastContainer";
import { placements, variants } from "./constants";
import type { Toast, ToastOptions, ToastPlacement } from "./interfaces";

export interface ToastContextProps {
  toasts: Toast[];
  show: (message: string, options?: ToastOptions) => void;
  success: (message: string, options?: ToastOptions) => void;
  error: (message: string, options?: ToastOptions) => void;
  remove: (id: string) => void;
  placement: ToastPlacement;
}

const ToastContext = createContext<ToastContextProps>({
  toasts: [],
  show: noop,
  remove: noop,
  success: noop,
  error: noop,
  placement: placements.bottom,
});
ToastContext.displayName = "ToastContext";

export const useToast = () => useContext<ToastContextProps>(ToastContext);

export interface ToastProviderProps {
  defaultOptions?: ToastOptions;
  children: ReactNode;
}

export const ToastProvider = ({
  children,
  defaultOptions = {},
}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [placement, setPlacement] = useState<ToastPlacement>(placements.bottom);

  const show = useCallback(
    (message: string, options?: ToastOptions) => {
      const toastOptions = { ...defaultOptions, ...options };
      const { placement: placementProp = placements.bottom } =
        toastOptions || {};
      const placement = placements[placementProp];

      const newItem: Toast = {
        id: uniqueId(),
        message,
        ...toastOptions,
      };

      setPlacement(placement);
      setToasts(prevState => [...prevState, newItem]);
    },
    [defaultOptions],
  );

  const success = (message: string, options?: ToastOptions) =>
    show(message, { ...options, variant: variants.success });

  const error = (message: string, options?: ToastOptions) =>
    show(message, { ...options, variant: variants.danger });

  const remove = (id: string) => {
    setToasts(prevState => prevState.filter(e => e.id != id));
  };

  window.onViewOnlyModeError = error;

  return (
    <ToastContext.Provider
      value={{
        toasts,
        show,
        success,
        error,
        remove,
        placement,
      }}
    >
      <Portal>
        <ToastContainer />
      </Portal>
      {children}
    </ToastContext.Provider>
  );
};
